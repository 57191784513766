.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* black with 70% opacity */
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    text-align: center;
    z-index: 1000;
    overflow: auto;
    position: relative; /* Ensures it's on top */
  }
  
  .modal code {
    white-space: nowrap;
    overflow: auto;
    background-color: #f9f9f9;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    width: 100%;
  }

  .integration-section {
    position: relative;
    /* additional styles */
  }
  
  .copy-button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    /* additional styles */
  }
  
  .script-tag {
    background-color: #f8f9fa;
    padding: 15px;
    /* additional styles */
  }
  
  
  .modal-content {
    @apply p-6;
  }
  
  .settings-dropdown {
    @apply w-full mt-4;
  }
  
  .settings-dropdown h3 {
    @apply text-xl font-bold mb-2;
  }
  
  .settings-dropdown select {
    @apply w-full p-2 mt-1 rounded border border-gray-300;
  }
  
  
  .modal code {
    background-color: #f9f9f9;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    overflow-wrap: break-word;
  }
  
  .modal button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 12px 20px;
    margin: 8px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
  }
  
  .modal button:hover {
    background-color: #45a049;
  }
  



.analytics-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}


/* Bar Chart Styles */
.Bar {
    max-width: 800px;
    margin: auto;
}

.analytics-card {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;  /* Ensures the container doesn't expand beyond the viewport width */
    overflow: hidden; /* Hide any overflowing content */
}

.chart-card, .table-card {
  max-width: 100%; 
  overflow: auto; /* Introduce scrollbars if content is too much */
}

/* If you're using flexbox or grid for layout, ensure constraints are set there too. */
.flex-container {
  display: flex;
  /* other flex properties */
}

.flex-item {
  flex-shrink: 0;   /* Prevents the flex item from shrinking */
  max-width: 100%; /* Ensures the item doesn't grow beyond its container */
  overflow: auto;  /* Introduce scrollbars if content is too much */
}

/* For the overall analytics container */
.analytics-container {
    padding: 20px;
}

/* For the row containing the charts */
.chart-row {
    display: flex;
    justify-content: space-between;
}

/* For each individual card */
.chart-card {
    flex: 1;
    margin: 10px;
    width: 50%;
}

/* For the table card */
.table-card {
    margin: 10px 0;
    width: 100%;
}