.auth-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
  }
  
  .auth-modal-card {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    width: 400px;
  }
  
  .auth-close-button {
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .auth-toggle-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1.5rem;
  }
  
  .auth-toggle-buttons button {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    border: none;
    background: none;
    border-bottom: 2px solid transparent;
  }
  
  .auth-toggle-buttons button.active {
    border-bottom: 2px solid blue;
  }
  
  .auth-form input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }
  
  .auth-submit-button {
    display: block;
    width: 100%;
    padding: 12px;
    background: blue;
    color: white;
    border: none;
    cursor: pointer;
  }
  